import BaseSkeleton from '@/components/base/baseSkeleton';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { getForumDetail } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useLocation } from 'umi';
import { TextTypeEnum } from '@/enums/businessEnum';

/**
 * 公告面板
 */
export default (props: { scrollElement?: HTMLElement }) => {
  const { state } = useLocation() as any;

  const id = state?.id;

  const { data, loading } = useReq(() => getForumDetail({ id }), {
    ready: id,
    formatResult: (res: any) => {
      return res?.data ?? {};
    },
  }) as any;



  return (
    <div>
      <BaseTopNav></BaseTopNav>
      <div className="p-4 ">
        <BaseSkeleton isLoaded={!data || loading}>
          <div className="flex flex-col justify-between h-[130px] px-4">
            <div className="h-[20px] w-1/3 bg-backgroundAuxiliaryColor rounded-md"></div>
            <div className="h-4 bg-backgroundAuxiliaryColor rounded-md mr-10"></div>
            <div className=" h-4 bg-backgroundAuxiliaryColor rounded-md mr-10"></div>
            <div className="h-4 bg-backgroundAuxiliaryColor rounded-md mr-10"></div>
            <div className="h-4 bg-backgroundAuxiliaryColor rounded-md mr-15"></div>
          </div>
        </BaseSkeleton>

        {data && !loading && (
          <>
            <div className="text-2xl text-backContrastColor text-center mb-4">
              {data?.messageTitle}
            </div>
            <FeaturesRenderText
              data={{
                content: data?.messageContent,
                editorType: TextTypeEnum.MARKDOWN,
              }}
              className="bg-[transparent]"
            />
          </>
        )}
      </div>
    </div>
  );
};
